<template>
    <modal ref="modalAplicarPago" titulo="Aplicar pago" :cargado-modal="loading" tamano="modal-lg" no-aceptar adicional="Aplicar" @adicional="pagarLiquidacion">
        <div class="row">
            <div class="col">
                <p class="text-general text-center f-15 lh-18">
                    Está a punto de poner al día los {{ liquidacion.total_pedidos }} créditos asociados <br /> a este Convenio por un valor de {{ separadorNumeroDecimales(liquidacion.total_liquidaciones) }}"
                </p>
                <p class="text-general text-center mt-2">
                    ¿Desea aplicar pago?
                </p>
            </div>
        </div>
        <div class="row mx-0 mt-4">
            <div class="col-4">
                <p class="text-general f-12 pl-3">
                    Cedis
                </p>
                <el-select v-model="idCedis" class="w-100" size="small" @change="loadCuentas">
                    <el-option
                    v-for="item in selectCedis"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-4">
                <p class="text-general f-12 pl-3">
                    Forma de pago
                </p>
                <el-select v-model="tipoCuenta" class="w-100" size="small" @change="idCaja = null">
                    <el-option label="Efectivo" :value="1" />
                    <el-option label="Transferencia" :value="2" />
                </el-select>
            </div>
            <div class="col-4">
                <p class="text-general f-12 pl-3">
                    Cuenta / Caja
                </p>
                <el-select v-model="idCaja" class="w-100" size="small">
                    <el-option
                    v-for="item in selectCuentas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>
<script>
import Nomina from '~/services/nomina/afiliados'
import Cuentas from '~/services/flujoDinero/cajas'
export default {
    props:{
        liquidacion:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return {
            idCedis:null,
            idCaja:null,
            tipoCuenta:1,
            loading:false,

            cuentas:[]

        }
    },
    computed:{
        idConvenio(){
            return Number(this.$route.params.id_convenio)
        },
        idLiquidacion(){
            return this.$route.params.id_liquidacion
        },
        selectCedis(){
            return this.cedis_calculo.filter(c=>c.id != 0)
        },
        selectCuentas(){
            return this.cuentas.filter(c=>c.tipo == this.tipoCuenta)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAplicarPago.toggle();
        },
        async loadCuentas(){
            try {

                const {data} = await Cuentas.getCuentasYCajasXCedis(this.idCedis)
                this.cuentas = data.cuentas

            } catch (error){
                this.error_catch(error)
            }
        },
        async pagarLiquidacion(){
            try {
                this.loading = true

                if(this.idCaja === null) return this.notificacion("Advertencia","Por favor seleccione un cuenta a la cual ingresara el dinero","warning")
                const form = {
                    tipo_pago: this.tipoCuenta,
                    id_caja:this.idCaja,
                    convenio:this.liquidacion.convenio,
                    valor:this.separadorNumeroDecimales(this.liquidacion.total_liquidaciones),
                    pedidos:this.liquidacion.total_pedidos
                }
                const {data} = await Nomina.pagarLiquidacion(this.idConvenio,this.idLiquidacion, form)
                this.notificacion("","Se ha pagado los pedidos correspondientes a esta liquidacion correctamente",'success')
                this.$refs.modalAplicarPago.toggle()
                this.$emit('update')

            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading =false
            }
        }


    }
}
</script>
